var aria = aria || {};

(function ($, Drupal) {
  Drupal.behaviors.contentBlockOverlayV1 = {
    attach: function (context) {
      var $modules = $('.js-content-block-overlay--v1', context);

      $modules.each(function () {
        var self = this;
        var $this = $(self);
        var $dialog = $this.find('[role="dialog"]');
        var $closeDialog = $('.js-content-block-overlay-close', $dialog);

        $this.off('launch.overlay').on('launch.overlay', function () {
          $(this).insertAfter($('body'));
          if (aria) {
            new aria.Dialog($dialog.attr('id'), self);
          }
        });
        $closeDialog.off('click.close').on('click.close', function () {
          var closeButton = $(this).get(0);

          if (aria) {
            var topDialog = aria.getCurrentDialog();
          }
          if (topDialog.dialogNode.contains(closeButton)) {
            topDialog.close();
          }
        });
      });
    }
  };
})(jQuery, Drupal);
